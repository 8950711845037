<template>
  <section class="section">
    <div class="row center-block">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-header with-border content">
            <h2>
              Assignments
              <span class="tag is-dark">PREMIUM</span>
            </h2>
            <div v-if="isAdmin">
              View all? <b-switch v-model="showAll" @input="switchDidChange">
                {{ showAll ? 'All' : 'Yours' }}
              </b-switch>
            </div>
            <div class="box-tools pull-right">
              <button v-if="$store.getters.isPremium || $store.getters.hasNumber" type="button" class="button" @click="showAdd">
                <span class="icon"><i class="mdi mdi-plus" /></span> <span>Create Assignment</span>
              </button>
            </div>
          </div>
          <!-- /.box-header -->
          <div class="box-body">
            <div v-if="$store.getters.isPremium || $store.getters.hasNumber">
              <div class="tabs is-toggle is-centered">
                <ul>
                  <li :class="{'is-active' : filter == 'active'}" @click="setFilter('active')">
                    <a>Current&nbsp;<span class="tag is-success">{{ activeCount }}</span></a>
                  </li>
                  <li :class="{'is-active' : filter == 'pending'}" @click="setFilter('pending')">
                    <a>Pending&nbsp;<span class="tag is-light">{{ pendingCount }}</span></a>
                  </li>
                  <li :class="{'is-active' : filter == 'expired'}" @click="setFilter('expired')">
                    <a>Expired&nbsp;<span class="tag is-danger">{{ expiredCount }}</span></a>
                  </li>
                </ul>
              </div>
              <b-table
                v-if="homeworkData"
                :data.sync="filteredHomeworkData"
                :paginated="true"
                :per-page="10"
                :mobile-cards="true"
                :striped="true"
                :selectable="false"
                :sort="true"
                default-sort-direction="desc"
                default-sort="expiry"
              >
                <template slot-scope="props">
                  <b-table-column field="title" class="content" label="Title" sortable>
                    <h5>
                      {{ props.row.title }}
                      <span v-if="showAll && isAdmin && props.row.owner_id == $store.state.user.id" class="tag is-light">YOURS</span>
                    </h5>
                    <p v-if="props.row.type == 'spelling'">
                      <span class="tag is-warning">SPELLING</span> {{ props.row.list_name }}
                    </p>
                    <p v-else-if="props.row.type == 'number'">
                      <span class="tag is-number">{{ $store.state.user.locale === 'en_US' ? 'MATH' : 'MATHS' }}</span> {{ getNumberGameTitle(props.row.list_ident) }}
                    </p>
                  </b-table-column>
                  <b-table-column field="available_from" label="From" sortable>
                    <span :class="'tag is-' + dateInRange(props.row.available_from, props.row.expiry)">{{ dateFromServerDate(props.row.available_from) || 'No Start' }}</span>
                  </b-table-column>
                  <b-table-column field="expiry" label="Expiry" sortable>
                    <span :class="'tag is-' + dateInRange(props.row.available_from, props.row.expiry)">{{ dateFromServerDate(props.row.expiry) || 'No Expiry' }}</span>
                  </b-table-column>
                  <b-table-column field="actions" label="">
                    <div class="btn-group is-pulled-right">
                      <router-link tag="button" :to="'/homework/' + props.row.id" type="button" class="btn button is-link">
                        Details
                      </router-link>
                      <button
                        v-if="$store.state.user.id == props.row.owner_id"
                        type="button"
                        class="btn button is-link dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span class="caret" />
                        <span class="sr-only">Toggle Dropdown</span>
                      </button>
                      <ul v-if="$store.state.user.id == props.row.owner_id" class="dropdown-menu">
                        <!-- <li>
                          <a href="#" @click="editHomework(props.row)" data-backdrop="static" data-keyboard="false" data-toggle="modal"
                            data-target="#addAssignmentModal">Edit</a>
                        </li> -->
                        <li>
                          <a href="#" @click.prevent="deleteHomework(props.row.id)">Delete</a>
                        </li>
                      </ul>
                    </div>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered content">
                      <p>
                        <b-icon
                          custom-class="far"
                          pack="fa"
                          icon="frown"
                          size="is-large"
                        />
                      </p>
                      <p>Nothing here.</p>
                    </div>
                  </section>
                </template>
              </b-table>
            </div>
            <div v-else class="col-sm-12 text-center content">
              <h2>Premium Feature</h2>
              <p>Upgrade to premium to use this feature.</p>
              <router-link v-if="isAdmin" to="/manage-subscription" class="btn button is-link" data-dismiss="modal">
                Upgrade
              </router-link>
            </div>
            <div v-else class="alert">
              <b>{{ response }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <AddHomework v-if="showAddModal" :homework-obj="editingHomework" @close="hideAdd" />
  </section>
</template>

<script>
import moment from 'moment'
import AddHomework from '@/components/views/components/AddHomework'
import StringConversions from '@/mixins/StringConversions.ts'
import { request } from '@/edshed-common/api'

export default {
  name: 'HomeworkView',
  components: {
    AddHomework
  },
  mixins: [StringConversions],
  data (router) {
    return {
      showAddModal: false,
      homeworkData: [],
      filteredHomeworkData: [],
      response: '',
      editingHomework: null,
      showAll: false,
      filter: 'active',
      activeCount: 0,
      pendingCount: 0,
      expiredCount: 0
    }
  },
  computed: {
    isAdmin () {
      return (this.$store.state.user.school.admin === 1)
    }
  },
  mounted () {
    if (this.$store.state.user.school.org_type === 'district') {
      this.$router.push({ path: '/' })
      return
    }
    this.$nextTick(() => {
      this.getHomeworkData()
    })
  },
  methods: {
    setFilter (filt) {
      this.filter = filt
      this.switchDidChange()
    },
    switchDidChange () {
      console.log(this.showAll ? 'all' : 'own')
      this.filteredHomeworkData = []
      this.activeCount = 0
      this.pendingCount = 0
      this.expiredCount = 0
      if (this.showAll) {
        for (const hw of this.homeworkData) {
          const sta = this.dateInRange(hw.available_from, hw.expiry)
          let gsta = 'success'
          if (this.filter === 'pending') {
            gsta = 'default'
          } else if (this.filter === 'expired') {
            gsta = 'danger'
          }
          console.log(sta)
          if (sta === 'success') {
            this.activeCount++
          } else if (sta === 'default') {
            this.pendingCount++
          } else if (sta === 'danger') {
            this.expiredCount++
          }

          if (sta === gsta) {
            this.filteredHomeworkData.push(hw)
          }
        }
      } else {
        for (const d of this.homeworkData) {
          if (d.owner_id === this.$store.state.user.id) {
            const st = this.dateInRange(d.available_from, d.expiry)
            let gst = 'success'
            if (this.filter === 'pending') {
              gst = 'default'
            } else if (this.filter === 'expired') {
              gst = 'danger'
            }
            console.log(st)
            if (st === 'success') {
              this.activeCount++
            } else if (st === 'default') {
              this.pendingCount++
            } else if (st === 'danger') {
              this.expiredCount++
            }
            if (st === gst) {
              this.filteredHomeworkData.push(d)
            }
          }
        }
      }
    },
    getHomeworkData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'homework', null)
        .then((response) => {
          const data = response.data
          this.homeworkData = data.homework
          if (!this.homeworkData || this.homeworkData.length === 0) {
            this.response = 'No Homework'
          }
          // console.log('readerData: ' + this.readerData)
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
          this.switchDidChange()
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    editHomework (hw) {
      this.editingHomework = hw
      this.showAddModal = true
    },
    deleteHomework (id) {
      const del = confirm('Are you sure you want to delete this Assignment? This cannot be undone and all data will be lost.')
      if (del) {
        this.toggleLoading()
        this.$store.commit('TOGGLE_LOADING')
        request('DELETE', 'homework/' + id, null)
          .then((response) => {
            this.getHomeworkData()
            // console.log('readerData: ' + this.readerData)
            this.toggleLoading()
            this.$store.commit('TOGGLE_LOADING')
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    showAdd () {
      this.showAddModal = true
    },
    hideAdd () {
      this.editingHomework = null
      this.showAddModal = false
      this.getHomeworkData()
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    },
    dateFromServerDate (dt) {
      if (dt === null || dt === '' || !moment(dt).isValid()) {
        return null
      }
      const m = moment(dt).local()
      // var utcOffset = new Date().getTimezoneOffset()
      // m.add({minutes: utcOffset})
      const dts = m.toDate().toLocaleDateString() + ' ' + m.format('HH:mm')
      return dts // m.format('DD/MM/YYYY HH:mm')
    },
    dateInRange (start, end) {
      let startDate = null
      if (start) {
        startDate = moment(start)
      }
      const now = moment()

      let expiry = null
      if (end) {
        expiry = moment(end)
      }
      if (start && startDate > now) {
        return 'default'
      } else if (end && expiry < now) {
        return 'danger'
      } else {
        return 'success'
      }
    }
  }
}
</script>
